//Google custom search
.google-search {
	td {
		border: 0;
	}
	.gsc-control-cse {
		font-family: inherit;
		border: none;
		background: none;
		padding: 0;
	}
	.gsc-control-cse form {
		margin: 0;
	}
	.gsc-control-cse table {
		margin: 0;
	}
	.gsc-input-box {
		border: 0;
		background: none;
		height: auto;
		box-shadow: none;
	}
	.gsc-search-box-tools .gsc-search-box .gsc-input {
		padding: 0;
	}
	input.gsc-input:-moz-placeholder {//In one rule they for some reason don't work
		font-size: 16px;
		color: #fff;
		opacity: 1;
		font-weight: 400;
	}
	input.gsc-input:-ms-input-placeholder {
		font-size: 16px;
		color: #fff;
		opacity: 1;
		font-weight: 400;
	}
	input.gsc-input::-webkit-input-placeholder {
		font-size: 16px;
		color: #fff;
		opacity: 1;
		font-weight: 400;
	}
	input.gsc-input::-moz-placeholder {
		font-size: 16px;
		color: #fff;
		opacity: 1;
		font-weight: 400;
	}
	button {
		position: absolute;
		right: 0;
		top: 3px;
		z-index: 1;
		&:focus,
		&:active {
			box-shadow: none;
		}
	}
	.gsc-results-wrapper-visible {
		letter-spacing: initial;
		line-height: initial;
	}
	// input {
	// 	&:focus {
	// 		~ button {
	// 			&:after {
	// 				opacity: 0;
	// 			}
	// 			&:before {
	// 				opacity: 1;
	// 			}
	// 		}
	// 	}
	// }
}
input.gsc-input {
	background: none!important;
	text-indent: 0!important;
	border: 0!important;
	border-bottom: 1px solid #fff!important;
	padding: 0 $grid-gutter-width 0 0!important;
	box-shadow: none;
	height: 31px!important;
	border-radius: 0;
	transition: border-bottom-color $trans-dur;
	&:focus {
		border-bottom-color: $corp-green!important;
	}
}
.gsc-control-cse table td {
	padding: 0;
}
td.gsc-search-button {
	width: 0!important;
}
button.gsc-search-button {
	padding: 0;
	background: none!important;
	border: 0;
	margin: 0;
	width: 22px;
	display: block;
}
button.gsc-search-button svg {
	display: none;
}
.gsib_b {
	display: none;
}
table.gsc-search-box td {
	vertical-align: top!important;
}

//Custom scroll bar
.mCSB_container{min-height: 100%;}
.mCSB_scrollTools {
	width: 4px;
	z-index: 1;
}

//Owl-carousel
.owl-carousel {
	.owl-item {
		img {
			width: auto;
		}
	}
}

//Chosen (selects)
.chosen-container-single {
	.chosen-single {
		color: inherit;
		z-index: 2;
		//border-bottom: 1px solid $corp-gray-lightest;
		//transition: border-bottom-color $trans-dur;
		div {
			width: $selectArrowWidth;
			right: 5px;
			b {
				display: block;
				width: $selectArrowWidth;
				border: $selectArrowHeight solid $corp-gray-1;
				border-bottom-color: transparent;
				border-left-color: transparent;
				border-right-color: transparent;
				position: absolute;
				right: 0;
				top: 50%;
				margin: -5px 0 0;
				transition: transform $trans-dur, border-top-color $trans-dur;
			}
		}
		> span {
			margin-right: 20px;
			font-weight: $semibold;
		}
		.country {
			display: none;
		}
	}
	&.chosen-container-active {
		div {
			b {
				border-top-color: $corp-green;
			}
		}
		hr {
			transform: scale(1);
			z-index: 2;
		}
	}
	&.chosen-with-drop {
		div {
			b {
				z-index: 1011;
				transform: translateY(-5px) scale(-1);
			}
		}
		.chosen-single {
			z-index: 1;
			> span {
				visibility: hidden;
			}
		}
		.chosen-drop {
			//z-index: 2;
		}
	}
}
.chosen-container {
	//width: 100%!important;
	font-size: 16px;
	display: block;
	.chosen-single {
		line-height: 31px;
	}
	.chosen-drop {
		top: 0;
		//z-index: 0;
		//
		//z-index: 2;
	}
	.chosen-results {
		padding: 0;
		margin: 0;
		box-shadow: $dropdownShadow;
		background: #fff;
		visibility: hidden;
		opacity: 0;
		z-index: 3;
		width: 0;
		height: 0;
		color: $corp-gray-1;
		li {
			&.highlighted {
				background: $corp-green;
			}
		}
	}
	&.chosen-with-drop {
		.chosen-results {
			width: auto;
			height: auto;
			margin-top: 5px;
			visibility: visible;
			opacity: 1;
			transition: opacity $trans-dur*2 $trans-dur;
		}
	}
}
.inline-select {
	position: relative;
	.form-group {
		[type="text"] {
			z-index: 2;
		}
	}
	.chosen-container,
	[class^="col-"] {
		position: static;
	}
	.chosen-search {
		div {
			position: relative;
		}
	}
	.col-xs-4 {
		.chosen-search {
			width: 1/3*100%;
			padding-right: $grid-gutter-width - $grid-gutter-width/3;
		}
	}
	.common-phone-code {
		.chosen-search {
			width: 65px;
		}
	}
}

.dark-theme {
	.chosen-container-single {
		.chosen-single {
			div {
				b {
					border-top-color: #fff;
				}
			}
		}
	}
}

.grecaptcha-badge {
	display: none;
}

//LP PAMM vidget on main page
.main-body {
	height: auto;
	min-height: 100%;
}
.dchart-lp-pamm-compact {
	// height: 230px;
	position: relative;
	z-index: 0;//to fix chart loader overlap of menu panel
	.dc-dchart-compact-footer,
	.dc-dchart-compact-header {
		// border: 1px solid #eaebec;
		// border-right: 0;
		height: auto;
		min-height: 50px;
	}
	.dc-dchart-compact-footer,
	.dc-dchart-compact-header,
	.dc-dchart-content {
		position: static!important;
	}
	// .dc-dchart-rising {
	// 	// margin-top: -1px;
	// 	// margin-bottom: -1px;
	// }
	.dc-dchart-compact-header {
		border: 1px solid #eaebec;
		// top: 180px;
		// bottom: auto;
		.dc-dchart-compact-header-value {
			padding-left: 20px;
			position: relative;
			&:before {
				background: url(../images/icons-svg-min/common/arrow-red.svg);
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -7px;
			}
			&.dc-dchart-rising {
				&:before {
					background: url(../images/icons-svg-min/common/arrow-green.svg);
				}
			}
		}
	}
	// .dc-dchart-compact-footer-title {
	// 	display: flex;
	// 	align-items: center;
	// }
	// // .dc-dchart-compact-footer-title,
	// .dc-dchart-compact-footer-value-wrapper,
	// .dc-dchart-compact-header-value {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// }
	// .dc-dchart-compact-header-title,
	// .dc-dchart-compact-header-title-date {
	// 	line-height: 15px;
	// }
	// .dc-dchart-compact-header-value-wrapper,
	// .dc-dchart-compact-footer-value {
	// 	line-height: 30px;
	// }
	// .dc-dchart-compact-header-value-wrapper,
	// .dc-dchart-compact-header-value {
	// 	font-size: 16px;
	// }
	.dc-dchart-compact-header-title,
	.dc-dchart-compact-footer-title {
		width: 65%;
	}
	.dc-dchart-compact-footer-value,
	.dc-dchart-compact-header-value-wrapper {
		width: 35%;
	}
	.dc-dchart-compact-header-value-wrapper,
	.dc-dchart-compact-header-title,
	.dc-dchart-compact-footer-value,
	.dc-dchart-compact-footer-title {
		padding: 9px;
	}
	.dc-dchart-compact-header-value-wrapper,
	.dc-dchart-compact-footer-value {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	// .dc-dchart-compact-header-title {
	// 	line-height: 17px;
	// 	border-top: 1px solid #eaebec;
	// 	border-left: 1px solid #eaebec;
	// }
	.dc-dchart-canvas-wrapper {
		border-left: 1px solid #eaebec;
		border-right: 1px solid #eaebec;
		position: relative!important;
		top: auto!important;
		right: auto!important;
		left: auto!important;
		bottom: auto!important;
		height: 130px;
		canvas {
			display: block;
			height: 130px!important;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
	.dc-dchart-compact-footer-title {
		// line-height: 15px;
		border-left: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
	}
}

//DC Support chat
.dccContainerChat {
	width: 100%!important;
}