//Т. к. такая реализация - временное решение, всё впихнул в один файл
@import "../../../../../static/src/scss/bootstrap/variables.scss";
@import "../../../../../static/src/scss/custom_variables";
@import "../../../../../static/src/scss/vendors/chosen.scss";
@import "../../../../../static/src/scss/custom/plugins_fixes.scss";

$screen-md-min: 1020px;
$screen-sm-max: $screen-md-min - 1px;

@keyframes dc-loader {
	0% {
		opacity: 0;
	}
	12.5% {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
}
.new-design {
	color: $corp-gray-1;
	font-family: $opensans;
	letter-spacing: $main-letter-spacing;
	*,
	:after,
	:before {
		box-sizing: border-box;
	}
	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.2);
		}
		100% {
			transform: scale(1);
		}
	}
	p {
		margin: 0;
		padding-top: $padding - $fzPLhPadding;
	}
	li {
		font-size: inherit;
	}
	button, input, select, textarea {
		font-family: inherit;
	}
	button {
		cursor: pointer;
	}
	.small-warning,
	.small-error {
		> div {
			font-size: 14px;
			line-height: $internalFz14LineHeight;
			padding-left: 40px;
			background-position: 0 5px;
			background-repeat: no-repeat;
			text-align: left;
		}
	}
	.small-warning {
		> div {
			background-image: url(/static/images/icons-svg-min/common/alert-triangle-small.svg);
			background-size: 24px 21px;
		}
	}
	.small-error {
		> div {
			background-image: url(/static/images/icons-svg-min/common/error-small.svg);
			background-size: 24px 24px;
			min-height: $internalFz14LineHeight + 2*5px;
			display: flex;
			align-items: center;
		}
	}
	.with-tooltip {
		position: relative;
		&:not(.ib):hover {
			.tooltip {
				visibility: visible;
				opacity: 1;
			}
			.show-tooltip:not(.dc-btn) {
				color: inherit;
			}
		}
		.show-tooltip {
			&:not(.dc-btn) {
				background: none;
				border: 0;
				color: $corp-gray-7;
				padding: 0;
				cursor: pointer;
				&:hover,
				&:active,
				&:focus {
					color: inherit;
					transition: color $trans-dur;
					+ .tooltip {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
		&.outside {
			&:hover,
			&:active,
			&:focus {
				+ .tooltip {
					visibility: visible;
					opacity: 1;
				}
			}
		}
		&.near-nav {
			+ .tooltip {
				z-index: $main-nav-index + 1;
			}
		}
		&.to-bottom {
			.tooltip {
				bottom: auto;
				top: 100%;
				padding-bottom: 0;
				padding-top: 11px;
				&:after {
					bottom: auto;
					top: 0;
					transform: rotate(180deg);
				}
			}
		}
		&.ib-tooltip {
			display: inline-block;
			position: static;
			.tooltip {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	.for-ib-tooltip {
		position: relative;
	}
	.tooltip {
		position: absolute;
		bottom: 100%;
		padding-bottom: 11px;
		font-size: 13px;
		line-height: $internalFz13LineHeight;
		color: #fff;
		text-transform: none;
		font-weight: $regular;
		visibility: hidden;
		opacity: 0;
		transition: opacity $trans-dur;
		z-index: 1;
		width: 100%;
		&:after {
			content: '';
			display: block;
			width: 16px;
			height: 11px;
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -8px;
			background: url(/static/images/icons-svg-min/hp/tooltip.svg);
		}
		> div {
			background: rgba(0,0,0,.9);
			padding: 10px 16px 12px;
			border-radius: $border-radius-common;
		}
		a {
			color: inherit;
			text-decoration: underline;
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}
		}
		p {
			padding-top: 10px;
		}
	}
	label {
		font-weight: 400;
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="number"] {
		appearance: none;
	}
	.material {
		font-size: $internalFontSize;
		line-height: $internalLineHeight;
		.form-group {
			position: relative;
			margin-bottom: 0;
			&.checkbox {
				margin-top: $padding - $fzPLhPadding;
				margin-bottom: 0;
				+ .checkbox {
					margin-top: $padding/2 - $fzPLhPadding;
				}
			}
		}
		hr {
			height: 1px;
			width: 100%;
			margin: 0;
			border: 0;
			position: absolute;
			bottom: 0;
			transition: transform $trans-dur;
			transform: scaleX(0);
			transform-origin: 50% 50%;
			&.focus {
				background: $corp-green;
			}
			&.error {
				background: $corp-red;
			}
		}
		label {
			margin: 0;
		}
		// [type="text"],
		// [type="email"],
		// [type="tel"],
		// textarea,
		[type="text"] + label,
		[type="email"] + label,
		[type="tel"] + label,
		[type="number"] + label,
		h5,
		.error-text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;
		}
		[type="text"],
		[type="email"],
		[type="tel"],
		[type="number"],
		textarea,
		[type="text"] + label,
		[type="email"] + label,
		[type="tel"] + label,
		[type="number"] + label,
		h5 {
			font-size: 16px;
			color: #fff;
		}
		[type="text"],
		[type="tel"],
		[type="email"],
		[type="number"],
		textarea {
			outline: none;
			position: relative;
			// z-index: 1;
			display: block;
			width: 100%;
			background: none;
			border: 0;
			border-bottom: 1px solid $corp-gray-8;
			padding: 0;
			transition: border-bottom-color $trans-dur;
			border-radius: 0;
			font-weight: $semibold;
			height: 31px;//for IE
			&:focus {
				//border-bottom-color: $corp-green!important;
				~ hr.focus {
					transform: scaleX(1);
					z-index: 2;
				}
			}
			+ label {
				position: absolute;
				bottom: 0;
				left: 0;
				transition-property: opacity, bottom, font-size, margin-bottom, line-height;
				transition-duration: $trans-dur;
			}
			&:-webkit-autofill {
				-webkit-animation: autofill-hack forwards;
			}
			&:placeholder-shown {
				border-bottom-color: $corp-gray-10;
			}
		}
		.textarea-div {
			min-height: 31px;
			font-weight: $semibold;
			visibility: hidden;
			opacity: 0;
		}
		textarea {
			height: 100%;
			resize: none;
			overflow: hidden;
			position: absolute;
			left: 0;
			top: 0;
			letter-spacing: inherit;
		}
		h5, .error-text {
			position: absolute;	
		}
		.entered label,
		textarea:focus + label,
		[type="text"]:focus + label,
		[type="email"]:focus + label,
		[type="tel"]:focus + label,
		[type="number"]:focus + label,
		h5,
		.error-text {
			font-size: 12px;
			line-height: $internalFz12LineHeight;
		}
		.entered label,
		textarea:focus + label,
		[type="text"]:focus + label,
		[type="email"]:focus + label,
		[type="tel"]:focus + label,
		[type="number"]:focus + label,
		h5 {
			margin-bottom: -5px;
			opacity: .5;
			bottom: 100%;
		}
		.form-radio {
			h5 {
				margin-bottom: 0;
			}
		}
		h5 {
			font-weight: $regular;
			margin-top: 0;
			text-align: left;
		}
		[type="radio"],
		[type="checkbox"] {
			//display: none;
			position: absolute;
			z-index: -1;
			opacity: 0;
			+ label {
				position: relative;
				padding-left: 27px;
				cursor: pointer;
				.checkmark {
					position: absolute;
					left: 0;
					top: 6px;
					transition: box-shadow $trans-dur;
					&:before,
					&:after {
						content: '';
						display: block;
						// position: absolute;
					}
					&:before {
						width: 18px;
						height: 18px;
						border: 1px solid $corp-gray-9;
					}
					&:after {
						position: absolute;
						top: 50%;
						left: 50%;
					}
				}
				&:hover {
					.checkmark {
						box-shadow: 0 0 0px 4px $corp-gray-11;
						&:before {
							
						}
					}
				}
			}
			&:focus {
				+ label {
					.checkmark {
						box-shadow: 0 0 0px 4px $corp-gray-10;
						&:before {
							
						}
					}
				}
			}
		}
		[type="radio"] {
			+ label {
				.checkmark,
				.checkmark:before,
				.checkmark:after {
					border-radius: 50%;
				}
				.checkmark {
					&:after {
						width: 8px;
						height: 8px;
						background: $corp-green;
						left: 5px;
						top: 5px;
						transform: scale(0);
						transition: transform $trans-dur;
					}
					&:before {
						
					}
				}
			}
			&:checked {
				+ label {
					.checkmark {
						&:after {
							transform: scale(1);
						}
					}
				}
			}
		}
		[type="checkbox"] {
			+ label {
				.checkmark,
				.checkmark:before {
					border-radius: $border-radius-common;
				}
				.checkmark {
					&:before {
						transition: background $trans-dur, border-color $trans-dur, box-shadow $trans-dur;
					}
					&:after {
						width: 6px;
						height: 12px;
						border: 2px solid #fff;
						border-left: 0;
						border-top: 0;
						transform: rotate(45deg) scale(0);
						transition: transform $trans-dur;
						margin: -7px 0 0 -3px;
					}
				}
			}
			&:checked {
				+ label {
					.checkmark {
						&:before {
							border-color: $corp-green;
							background: $corp-green;
						}
						&:after {
							transform: rotate(45deg) scale(1);
						}
					}
				}
			}
		}
		.inline-radio-block {
			padding-right: $grid-gutter-width;
			&:last-child {
				padding-right: 0;
			}
		}
		.error-text {
			right: 0;
			// margin-top: 3px;
			color: $corp-red;
			visibility: hidden;
			opacity: 0;
			transition: opacity $trans-dur $trans-dur;
		}
		.has-error {
			hr.error {
				transform: scaleX(1);
				z-index: 2;
			}
			.error-text {
				visibility: visible;
				opacity: 1;
			}
		}
		.captcha-block {
			display: none;
		}
		.captcha-img {
			margin: 0 auto 28px;
			cursor: pointer;
			display: block;
		}
		.submit {
			.btn-text-block {
				transition: opacity $trans-dur;
			}
		}

		&.light-theme {
			textarea,
			[type="text"],
			[type="tel"],
			[type="email"],
			[type="number"] {
				color: inherit;
			}
			textarea + label,
			[type="text"] + label,
			[type="email"] + label,
			[type="tel"] + label,
			[type="number"] + label,
			h5 {
				color: $corp-gray-7;
			}
		}
		&.dark-theme {
			color: #fff;
			[type="text"],
			[type="tel"],
			[type="number"],
			[type="email"] {
				border-bottom-color: #fff;
				&:placeholder-shown {
					border-bottom-color: $corp-gray-9;
				}
			}
		}
		// &.type-1 {//without popup and loader with dots
			.content-block {
				position: relative;
			}
			.sending-layer {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background: rgba(255, 255, 255, 0.75);
				z-index: 3;
				transition: opacity $trans-dur;
			}
			// &.main-demo-form-block {
			// 	.sending-layer {
			// 		bottom: 24px;
			// 		&:before,
			// 		&:after {
			// 			content: '';
			// 			display: block;
			// 			position: absolute;
			// 			top: 100%;
			// 			background: rgba(255, 255, 255, 0.75);
			// 			height: 24px;
			// 		}
			// 		&:before {
			// 			left: 0;
			// 			right: 24px;
			// 		}
			// 		&:after {
			// 			width: 24px;
			// 			right: 0;
			// 			background: linear-gradient(135deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75) 50%, transparent 50%);
			// 		}
			// 	}
			// }
			&.sending,
			&.receiving-data {
				// position: relative;
				.sending-layer {
					visibility: visible;
					opacity: 1;
				}
			}
			.submit {
				position: relative;
			}
		// }
		.beauty-checkboxes {
			[type="checkbox"] {
				+ label:hover,
				&:focus + label,
				&:checked + label {
					.checkmark {
						margin-top: -13px;
						margin-left: -13px;
						&:before {
							width: 26px;
							height: 26px;
						}
						&:after {
							transform: rotate(45deg) scale(1);
						}
					}
				}
				+ label:hover,
				&:focus + label {
					&:before {
						border-color: $corp-gray-8;
					}
					.checkmark {
						box-shadow: none;
						&:before {
							// width: 18px;
							// height: 18px;
							background: $corp-gray-8;
						}
						&:after {
							// transform: rotate(45deg) scale(.5);
						}
						// border-radius: 50%;
					}
				}
				+ label {
					font-size: 14px;
					font-weight: $bold;
					text-transform: uppercase;
					display: block;
					// border: 1px solid $corp-gray-10;
					// border-radius: $border-radius-common;
					text-align: center;
					padding: 15px;
					margin-bottom: 13px;
					// transition-property: border-color, background-color, box-shadow;
					// transition-duration: $trans-dur;
					&:before {
						content: '';
						display: block;
						position: absolute;
						z-index: 1;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: #fff;
						border-radius: $border-radius-common;
						border: 1px solid $corp-gray-10;
						transition-property: box-shadow, border;
						transition-duration: $trans-dur;
					}
					&:after {
						content: '';
						display: block;
						width: 26px;
						height: 26px;
						border-radius: 50%;
						// border-bottom-right-radius: 13px;
						position: absolute;
						top: 100%;
						left: 50%;
						margin: -13px 0 0 -13px;
						// border: 4px solid #000;
						transition: box-shadow $trans-dur;
					}
					.checkmark {
						z-index: 2;
						left: 50%;
						top: 100%;
						margin-top: -5px;
						margin-left: -5px;
						transition-property: margin-top, margin-left;
						transition-duration: $trans-dur;
						// &:before,
						// &:after {
						// 	// z-index: 1;
						// }
						&:before {
							border-radius: 50%;
							width: 10px;
							height: 10px;
							border: 0;
							background: $corp-gray-10;
							transition-property: width, height, background-color;
							transition-duration: $trans-dur;
						}
						&:after {
							margin: -7px 0 0 -3px;
						}
					}
					.text {
						position: relative;
						z-index: 1;
					}
					&:hover {
						// background: $corp-gray-11;
						&:before {
							// border-color: $for-lines;
						}
						.checkmark {
							&:before {
								// background: $for-lines;
							}
							&:after {
								// transform: rotate(45deg) scale(1);
							}
						}
					}
				}
				&:focus {
					+ label {
						// border-color: $for-lines;
						.checkmark {
							&:before {
								
								// width: 26px;
								// height: 26px;
								// box-shadow: none;
							}
							&:after {
								// transform: scale(1) rotate(45deg);
							}
						}
					}
				}
				&:checked {
					+ label {
						&:before {
							border-color: $corp-green;
							border-width: 2px;
							// box-shadow: 0 0 0 1px $corp-green;
						}
						.checkmark {
							animation: pulse $trans-dur*2;
							&:before {
								background: $corp-green;
							}
							&:after {
								
							}
						}
						
						&:hover {
							// box-shadow: 0 0 0 1px $corp-green, 0 0 0 4px $corp-gray-11;
							&:before {
								// box-shadow: 0 0 0 1px $corp-green, 0 0 0 4px $corp-gray-11;
								box-shadow: 0 0 0 4px $corp-gray-11;
								// box-shadow: 0 0 0 4px $corp-gray-11;
								// border-color: red;
							}
							&:after {
								box-shadow: 0 0 0 4px $corp-gray-11;
							}
						}
					}
					&:focus {
						+ label {
							&:before {
								// box-shadow: 0 0 0 1px $corp-green, 0 0 0 4px $corp-gray-10;
								box-shadow: 0 0 0 4px $corp-gray-10;
							}
							&:after {
								box-shadow: 0 0 0 4px $corp-gray-10;
							}
						}
					}
				}
			}
		}
	}
	.dc-loader,
	.dc-loader div {
		position: absolute;
		width: 10px;
		height: 10px;
		background: #fff;
	}
	.dc-loader {
		top: 50%;
		left: 50%;
		margin-top: -5px;
		margin-left: -5px;
		visibility: hidden;
		opacity: 0;
		transition: opacity $trans-dur, visibility 0s $trans-dur;
		// animation: dc-loader $trans-dur*4 infinite;
		div {
			opacity: 0;
			// transition: opacity $trans-dur;
			animation-duration: $trans-dur*8;
			animation-iteration-count: infinite;
			// animation-fill-mode: backwards;
			// animation-direction: alternate;
			animation-timing-function: ease-in;
			animation-name: dc-loader;
			&:nth-child(1) {
				animation-delay: -$trans-dur*6;
				bottom: 100%;
				// animation-name: dc-loader-1;
			}
			&:nth-child(2) {
				animation-delay: -$trans-dur*4;
				left: 100%;
				// animation-name: dc-loader-2;
			}
			&:nth-child(3) {
				animation-delay: -$trans-dur*2;
				top: 100%;
				// animation-name: dc-loader-3;
			}
			&:nth-child(4) {
				
				right: 100%;
				// animation-name: dc-loader-4;
			}
		}
	}
	.material.sending .submit .btn-text-block,
	.support-chat-btn.loading .icon-chat {
		opacity: 0;
		visibility: hidden;
		transition: opacity $trans-dur, visibility 0s $trans-dur;
	}
	.material.sending .submit,
	.support-chat-btn.loading {
		.dc-loader {
			opacity: 1;
			visibility: visible;
			transition: opacity $trans-dur $trans-dur;
		}
	}
	/*plugins_fixes*/
	@media (min-width: $screen-sm-min) {
		.inline-select {
			.col-sm-3 {
				.chosen-search {
					width: 1/4*100%;
					padding-right: $grid-gutter-width - $grid-gutter-width/4;
				}
			}
		}
	}
	
	@media (min-width: $screen-md-min) {
		.inline-select {
			.col-md-4 {
				.chosen-search {
					width: 1/3*100%;
					padding-right: $grid-gutter-width - $grid-gutter-width/3;
				}
			}
		}
	}

	/*START margins.scss*/
	.form-input {
		// padding-top: 25px;
		padding-top: 35px;
		&:first-child {
			padding-top: 0;
		}
	}
	.form-checkbox {
		padding-top: $padding - $fzPLhPadding;
		+ .form-checkbox {
			padding-top: 20px - 6px*2;//6px - above and under checkmark
		}
	}
	.form-radio {
		padding-top: 40px;
	}
	.form-submit {
		padding-top: $padding - $fzPLhPadding;
	}
	.block-top {
		padding-top: $padding - $fzPLhPadding;//21px
	}
	/*END margins.scss*/


	.common-phone-code {
		width: 65px;
		float: left;
	}
	.common-phone-number {
		margin-left: 80px;
	}


	.fixed-left-col-block {
		position: sticky;
		top: $main-nav-height;
		z-index: 1;
	}
	.qd-block {
		line-height: $internalLineHeight;
		padding: (25px - $fzPLhPadding) 15px (25px - $fz14LhPadding);
		border: 2px solid $corp-gray-11;
		font-size: $internalFontSize;
		background: #fff;
		position: relative;
		z-index: 3;//red line in footer overlaied disclaimer popup
		h2 {
			margin-bottom: 22px;//20px to focused label
		}
		.demo-top-error-block {
			margin-top: -12px;
		}
		.disclaimer {
			margin-top: 12px;
			text-align: center;
		}
		h3 {
			padding-top: 0;
		}
		.checkbox-block {
			margin-top: $padding - $fzPLhPadding - 25px;
			margin-bottom: $padding - $fzPLhPadding - 21px;
		}
		.submit-btn-block {
			padding-top: 21px;
		}
		.platform-info {
			.logo-block {
				// text-align: center;
				img {
					display: block;
					margin: 0 auto;
				}
			}
		}
		.bottom-error-block {
			margin-top: $padding - $fz14LhPadding - $fzPLhPadding;
		}
		.ib-tooltip {
			.tooltip {
				> div {
					margin-left: -15px;
					margin-right: -15px;
				}
			}
		}
	}
	.form-block {
		.success-block,
		.error-block,
		.api-error-block {
			display: none;
		}
	}
	.form-block {
		.demo-top-error-block,
		.bottom-error-block {
			display: none;
		}
		.demo-top-error-block {
			margin-bottom: 30px;
		}
		.captcha-block {
			padding: ($padding - $fzPLhPadding) 0 $fzPLhPadding;
		}
	}
	.success-block {
		.platform-info {
			display: none;
		}
		.platforms-info,
		.direct-login {
			padding-bottom: $fz14LhPadding;
		}
		.important-points.type-link > * > a {
			padding: 12px 20px;//20px - to fit "Check other available platforms" in one row
		}
		.main-text {
			padding-bottom: $padding - $fzPLhPadding;
			border-bottom: 1px solid $corp-gray-8;
			.email {
				word-wrap: break-word;
			}
		}
		.links-block {
			padding-top: $padding;
		}
		.platform-info {
			padding-top: $padding/2*3 - $fzPLhPadding;
		}
	}
	.dc-btn {
		display: inline-block;
		text-align: center;
		border-radius: $border-radius-common;
		font-size: 14px;
		line-height: 25px;
		padding: 10px 20px;
		border: none;
		outline: none;
		color: #fff;
		text-transform: uppercase;
		transition: background $trans-dur, color $trans-dur, border $trans-dur;
		font-weight: $semibold;
		&:hover,
		&:focus,
		&:active {
			color: #fff;
		}
		&[disabled] {
			background: $corp-gray-6!important;
			cursor: not-allowed!important;
		}
	}
	a.dc-btn {
		&:hover,
		&:active,
		&:focus {
			// color: #fff;
			text-decoration: none;
		}
	}
	.dc-btn-demo {
		background-color: $corp-green;
		&:hover,
		&:focus {
			background-color: $corp-green*$blackout;
		}
		&:active {
			background-color: $corp-green*$blackout*$blackout;
		}
	}
	.dc-btn-live {
		background: $corp-violet;
		&:hover,
		&:focus {
			background: $corp-violet*$blackout;
		}
		&:active {
			background: $corp-violet*$blackout*$blackout;
		}
	}
	.dc-btn-binary {
		background: $corp-blue;
		&:hover,
		&:focus {
			background: $corp-blue*$blackout;
		}
		&:active {
			background: $corp-blue*$blackout*$blackout;
		}
	}
	.dc-btn-transp {
		border: 1px solid $corp-gray-8;
		color: #000;
		&:hover,
		&:focus {
			border-color: $corp-red;
			color: $corp-red;
		}
		&:active {
			border-color: $corp-red*$blackout;
			color: $corp-red*$blackout;
		}
	}
	.dc-btn-big {
		padding: 16px 30px 17px;
		font-size: 18px;
	}
	.dc-btn-full-width {
		width: 100%;
	}
	.ta-center {
		text-align: center;
	}
	.ta-left {
		text-align: left;
	}
	.embed-block {
		display: none;
	}

	//Fixing site styles
	h2 {
		padding-bottom: 0;
		border-bottom: none;
		margin: 0;
	}
	h5 {
		font-family: inherit;
		padding-bottom: 0;
		border-bottom: none;
	}
	.text {
		font-size: inherit;
	}
	.progress-bar {
		height: 8px;
		background: $corp-gray-10;
		// transition: background-color $trans-dur;
		.progress {
			height: 100%;
			width: 0;
			transition-property: width, background-color;
			transition-duration: $trans-dur;
			transition-delay: $trans-dur;//while .sending-layer will disappear
		}
	}
	.main-demo-form {
		.small-warning {
			padding-top: 0;
		}
		.progress-bar {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
		.acc-sett-tab {
			visibility: hidden;
			opacity: 0;
			transition: opacity $trans-dur, visibility 0s $trans-dur;
		}
		&.settings-got {
			.progress-bar {
				.progress {
					background: $corp-green;
					width: 1/6*100%;
				}
			}
		}
		&.api-error {
			.progress-bar {
				.progress {
					width: 100%;
					background: $corp-red;
				}
			}
		}
		&.successfully-sent {
			.progress-bar {
				.progress {
					transition-delay: 0s;
					width: 5/6*100%;
				}
			}
		}
		&.last-step-done {
			.progress-bar {
				.progress {
					width: 100%;
				}
			}
		}
		.btn-text {
			display: none;
			// overflow: hidden;
			// &.submit {
			// 	display: block;
			// }
			&.active {
				display: block;
			}
			// &.next {
			// 	visibility: hidden;
			// 	opacity: 0;
			// 	// height: 0;
			// 	transition: opacity $trans-dur*10, visibility 0s $trans-dur*10;
				
			// }
			// &.submit {
			// 	transition: opacity $trans-dur*10 $trans-dur*10;
			// }
		}
		// .action-block-next {
		// 	display: none;
		// }
		&.custom-acc-settings {
			.pd-footer-block {
				// .action-block-next {
				// 	display: block;
				// }
				// .action-block-submit {
				// 	display: none;
				// }
			}
			// .btn-text {
			// 	&.next {
			// 		visibility: visible;
			// 		opacity: 1;
			// 		// height: auto;
			// 		transition: opacity $trans-dur*10, visibility 0s $trans-dur*10;
			// 	}
			// 	&.submit {
			// 		visibility: hidden;
			// 		opacity: 0;
			// 		// height: 0;
			// 		transition: opacity $trans-dur*10 $trans-dur*10;
			// 	}
			// }
			.acc-sett-tab {
				visibility: visible;
				opacity: 1;
				transition: opacity $trans-dur;
			}
			// .default-title {
			// 	display: none;
			// }
			// .alternate-title {
			// 	display: block;
			// }
		}
		&:not(.validated),
		&:not(.custom-acc-settings) {
			.dc-tabs {
				li {
					position: relative;
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		&:not(.validated) {
			.dc-tabs {
				a {
					color: $corp-gray-9;
				}
				.active {
					color: $corp-green;
				}
			}
		}
		.account {
			display: none;
		}
		.dc-tabs {
			.active {
				color: $corp-green;
			}
		}
		.bottom-error-block {
			> div {
				padding-top: $padding/2*3 - $fz14LhPadding - $fzPLhPadding;
			}
		}
	}
	//Tabs
	.dc-tabs {
		color: $corp-gray-7;
		text-transform: uppercase;
		font-weight: $semibold;
		a {
			
		}
	}
	.dc-tabs-content {
		transition: min-height $trans-dur;
	}
	.dc-tab-content {
		display: none;
		&.active {
			display: block;
		}
	}
	.trader-account {
		.form-radio {
			display: none;
		}
	}
	.trader-account-active {
		.trader-account {//currency and blance selects
			display: block;
		}
		.jforex-info {//links in success block
			display: block;
			padding-top: $padding;
		}
	}
	.mt4-account-active {
		.mt4-account {//currency and blance selects
			display: block;
		}
		.mt4-info {//links in success block
			display: block;
		}
		&:not(.trader-account-active) {
			.mt4-info {
				padding-top: $padding;
			}
		}
	}
	.binary-account-active {
		.binary-account {//currency and blance selects
			display: block;
		}
		.binary-info {//links in success block
			display: block;
		}
		&:not(.trader-account-active):not(.mt4-account-active) {
			.binary-info {
				padding-top: $padding;
			}
		}
	}


	//Must be left
	.disclaimer {
		.fake-link {
			color: $corp-red;
			text-decoration: underline;
		}
	}
}

.new-design,
.page-5319,
.page-15818,
.page-15789 {
	.success-block h3,
	.content-h3 {
		font-weight: $bold;
		text-transform: uppercase;
		color: #000;
		line-height: inherit;
		font-size: 16px;
	}
	.fadeOutSlideUp {
		opacity: 0;
		transition: opacity $trans-dur;
		&.show {
			opacity: 1;
		}
	}
	.slideDownFadeIn {
		opacity: 0;
		transition: opacity $trans-dur;
		&.shown {
			opacity: 1;
			// transition: opacity $trans-dur $trans-dur;
		}
	}
	.platform-info {
		display: none;
	}
	.trader-account-active {
		.trader-account {//currency and blance selects
			display: block;
		}
		.jforex-info {//links in success block
			display: block;
			padding-top: $padding;
		}
	}
	.mt4-account-active {
		.mt4-account {//currency and blance selects
			display: block;
		}
		.mt4-info {//links in success block
			display: block;
		}
		&:not(.trader-account-active) {
			.mt4-info {
				padding-top: $padding;
			}
		}
	}
}

//Fixing site styles
.page-5319,
.page-15818,
.page-15789 {
	background-color: $corp-gray-11!important;
	.h1-block .headerSeparator {
		display: none;
	}
	.footer-top {
		margin-top: 0;
	}
	footer {
		background-color: #fff;
	}
	.leftMenu {
		display: none;
	}
	.mainContentBody {
		width: 100%;
		float: none;
		padding: 0;
		* {
			box-sizing: border-box;
		}
		a {
			text-decoration: none;
			font-family: inherit;
		}
	}
	h1 {
		font: $h1;
		margin: 0;
		text-transform: uppercase;
		border: none;
		&:after {
			content: none;
		}
		padding-top: $padding*2 - 5px - $fzH1LhPaddingTop;
		padding-bottom: 0;
	}
	.content-h6 {
		font-weight: $regular;
		font-style: italic;
		color: $corp-gray-7;
		line-height: $internalLineHeight;
		font-size: 16px;
	}
	h6 {
		margin-top: 12px;
	}
	.form-block,
	.h1-block,
	.left-col-success-block {
		font-size: 16px;
		line-height: $internalLineHeight;
		p {
			margin: 0;
		}
	}
	.main-demo-form-block {
		margin-bottom: -200px;
		// margin-bottom: 60px;
	}
	.form-main-block {
		background: #fff;
		box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.07);
		padding: (80px + 8px - $fzPLhPadding) 60px (80px - 24px - $fz14LhPadding);//+24px bottom corner
		position: relative;
	}
	.form-bottom-block {
		position: relative;
		background: #fff;
		height: 24px;
		margin-right: 24px;
		box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.07);
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
		}
		&:before {
			width: 100%;
			height: 4px;
			bottom: 100%;
			background: #fff;
		}
		&:after {
			background: linear-gradient(135deg, #f1f1f1, #ddd 50%, transparent 50%);
			width: 24px;
			height: 24px;
			left: 100%;
			top: 0;
		}
	}
	.demo-form {
		
		// .form-field {
		// 	margin-top: 10px;
		// }
		.account-data {
			margin-top: -$padding*3/2 + $fzPLhPadding*2;
			// padding-top: 48px;
		}
		.account {
			padding-top: $padding*3/2 - $fzPLhPadding*2;
			h2 {
				text-align: left;
				font-weight: 600;
				margin-bottom: -9px;
				line-height: $internalLineHeight;
				text-transform: uppercase;
				font-size: 16px;
			}
		}
		.binary-account,
		.mt4-account {
			padding-bottom: $fzPLhPadding;
		}
		.checkboxes {
			padding-top: 45px - $fzPLhPadding;
			p {
				padding-top: 0;
			}
		}
		.beauty-checkboxes {
			padding-bottom: 4px;
		}
		.submit-btn-block {
			padding-top: 45px - $fzPLhPadding;
		}
		.disclaimer-block {
			.always-shown {
				padding-top: $padding - $fzPLhPadding*2 - $fz14LhPadding;
			}
		}
		.disclaimer {
			button {
				font-size: 14px;
				// color: $corp-gray-lighter;
			}
		}
		.action-block {
			padding-bottom: $fz14LhPadding;
		}
		&.inactive {
			.submit {
				background-color: $corp-gray-8;
				cursor: not-allowed;
			}
		}
	}
	.success-block {
		.first-row {
			padding-bottom: $padding/2*3 - $fzPLhPadding;
			border-bottom: 1px solid $corp-gray-8;
			margin-bottom: $padding/2*3 - $fzPLhPadding;
		}
	}
	.bullet-points {
		li {
			> * {
				position: relative;
				padding-left: 32px;
					&:before {
					content: '';
					display: block;
					width: 9px;
					height: 15px;
					border: 2px solid $corp-green;
					border-top: 0;
					border-left: 0;
					transform: rotate(45deg);
					position: absolute;
					left: 3px;
					top: 4px;
				}
			}
		}
	}
	ul, ol {
		&.styleless {
			padding-left: 0;
			padding-right: 0;
			list-style: none;
			li {
				list-style: none;
			}
		}
	}
	.styleless-a a,
	a.styleless {
		color: inherit;
	}
	.bread-h1 {
		padding: 0 10px;
	}
	.icon {
		display: inline-flex;
		align-items: center;
		color: inherit;
		padding-left: 63px;//48px max icon width + 15px
		position: relative;
		min-height: 60px;
		// color: $corp-gray;
		h3 {
			padding-top: 0;
		}
		&:before {
			content: '';
			display: block;
			flex-shrink: 0;
			background-repeat: no-repeat;
			// margin-right: $grid-gutter-width/2;
			transition: transform $trans-dur;
			position: absolute;
			left: 0;
			top: 6px;//(60 - 48)/2
		}
		&:hover,
		&:active,
		&:focus {
			&:before {
				transform: scale((52/48));
			}
		}
		&.icon-jforex {
			&:before {
				width: 48px;
				height: 48px;
				background-image: url('/static/images/icons-svg-min/hp/jforex.svg');
			}
		}
		&.icon-ios {
			&:before {
				width: 48px;
				height: 48px;
				background-image: url('/static/images/icons-svg-min/hp/app-store.svg');
			}
		}
		&.icon-android {
			&:before {
				width: 42px;
				height: 47px;
				background-image: url('/static/images/icons-svg-min/hp/google-play.svg');
			}
		}
		&.icon-refresh {
			min-height: auto;
			padding-left: 0;
			&:before {
				position: static;
				width: 12px;
				height: 12px;
				background-image: url('/static/images/icons-svg-min/hp/quotes-table/arrow.svg');
			}
		}
		&.icon-video {
			&:before {
				width: 48px;
				height: 48px;
				background-image: url('/static/images/icons-svg-min/common/play-video.svg');
			}
		}
		&.icon-video-outline {
			&:before {
				width: 48px;
				height: 48px;
				background-image: url('/static/images/icons-svg-min/common/play-video-outline.svg');
			}
		}
		&.icon-pdf-outline {
			&:before {
				width: 40px;
				height: 48px;
				background-image: url('/static/images/icons-svg-min/common/pdf-outline.svg');
			}
		}
		&.icon-h-24 {
			&:before {
				width: 52px;
				height: 48px;
				background-image: url('/static/images/icons-svg-min/common/24h.svg');
			}
		}
		&.icon-manual {
			&:before {
				width: 39px;
				height: 47px;
				background-image: url('/static/images/icons-svg-min/common/manual.svg');
			}
		}
	}
	.success-block {
		.platforms-info,
		.direct-login {
			padding-bottom: $fz14LhPadding;
		}
		.platform-info {
			padding-top: $padding/2*3 - $fzPLhPadding;
		}
	}
	.success-block,
	.error-block,
	.api-error-block {
		display: none;
	}
	.main-bottom-block {
		background-repeat: no-repeat;
		background-position: 50% 0;
		height: 346px;
		background-image: url('/static/images/internal-bckgds/demo.jpg');
	}
	.fz-14 {
		font-size: 14px;
		line-height: 24px;
	}
	.footUpper {
		margin-top: 0;
	}
	&.japan {
		.left-col-success-block {
			&:not(.trader-account-active) {
				.first-row {
					display: none;
				}
			}
		}
		.icon {
			&.icon-h-24 {
				&:before {
					width: 46px;
					height: 46px;
					background-image: url('/static/images/icons-svg-min/common/phone-48.svg');
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.page-5319,
	.page-15818,
	.page-15789 {
		.main-demo-form-block {
			margin-top: 90px;
		}
		.form-col {
			float: right;
		}
		.bread-h1 {
			float: left;
			width: 50%;
		}
		.features {
			margin: 105px 0 84px;
		}
		.left-col-success-block {
			margin: 110px 0;
		}
		.bullet-points {
			li {
				margin-bottom: 42px;
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	
	.page-5319,
	.page-15818,
	.page-15789 {
		.features {
			margin: 22px 0 58px;
		}
		.left-col-success-block {
			margin: 45px 0 60px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.page-5319,
	.page-15818,
	.page-15789 {
		.hidden-xs {
			display: none!important;
		}
		.main-demo-form-block {
			margin-top: 35px;
		}
		.form-main-block {
			padding-left: $forms-xs-padd;
			padding-right: $forms-xs-padd;
		}
		.h1-block {
			display: block!important;
			height: auto!important;
			opacity: 1!important;
		}
		.demo-form {
			.beauty-checkbox-col {
				padding-bottom: $padding/2;
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
}


@media (max-width: $xs-max-container) {
	.page-5319,
	.page-15818,
	.page-15789 {
		.form-main-block {
			padding: (50px + 8px - $fzPLhPadding) $forms-after-xs-padd (50px - 24px - $fz14LhPadding);
		}
	}
}
@media (max-width: 415px) {//Content to be like on 320px. Also in popup.scss
	.page-5319,
	.page-15818,
	.page-15789 {
		.form-main-block {
			padding: (40px + 8px - $fzPLhPadding) $grid-gutter-width/2 (40px - 24px - $fz14LhPadding);
		}
	}
}